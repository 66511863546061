* {
  box-sizing: border-box;
}

html {
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
}

html,
input {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
    sans-serif;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #263346;
}

.root {
  height: 100vh;
  padding: 0.5em;
  border: 10px solid #263346;
  border-radius: 20px;
  background-color: #fafafa;
}

@media screen and (max-width: 600px) {
  /* .root{
    height: fit-content;
  } */
}
