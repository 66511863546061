.main {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 1fr;
  grid-gap: 10px;
}

.footer-area > ul > li {
  float: left;
  margin: 5px;
}

a {
  text-decoration: none;
  color:#0087ff;
}

a:hover {
  color:#3a3260;
}

.video-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0;
  background-color: #3a3260;
  border-radius: 10px;
}

.bottom-area {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0;
}

.video video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video.remote video {
  border-radius: 10px 0 0 10px;
}

.video.local video {
  border-radius: 0 10px 10px 0;
}

.video {
  position: relative;
  height: 100%;
  width: 100%;
}

#localSettings {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 6px;
}

#localSettings button, #remoteSettings button {
  outline: none;
  border: none;
  border-radius: 10px;
  transition: .2s;
  cursor: pointer;
  padding: 8px;
  margin: 10px 0 0;
}

#localSettings button {
  margin-right: 10px;
}

.toggleSound {
  left: 5px;
  position: absolute;
  bottom: 5px;
}

#volumeOn, #micOn {
  display: none;
}

.next {
  background-color: #51fd6d;
  width: 40px;
  right: 5px;
  position: absolute;
  bottom: 5px;
}

/* #localSettings button {
  display: none;
} */

.remoteGenderLabel {
  display: none;
  background-color: #fff;
  border-radius: 8px;
  padding: 6px;
  position: absolute;
  top: 5px;
  left: 5px;
}

#genderMale {
  color: #0087ff;
}

#genderFemale {
  color: #f0d;
}

#genderLGBT {
  color: #8d06fd;
}

#genderCouple {
  color: #ffae18;
}

#genderGroup {
  color: #ff3e04;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
  width: 200%;
}

@media screen and (max-width: 600px) {
  .main {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1.5fr 1fr;
    grid-gap: 10px;
  }
  .footer-area {
    color: #fff;
  }
  .video-area {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    border-radius: 10px;
    min-height: 80vh;
  }
  .bottom-area {
    display: grid;
    grid-template-columns: 1fr;
    /* grid-template-rows: minmax(150px,1fr) 2fr; */
    grid-gap: 10px;
  }
  .video video {
    border-radius: none;
  }
  
  .video.local {
    border: solid;
  }

  .video.local video {
    border-radius: 0 0 10px 10px;
  }

  .video.remote video {
    border-radius: 10px 10px 0 0;
  }
}