.left {
  background-color: #263346;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.buttons > button {
  margin: 0 10px;
  padding: 15px;
  outline: none;
  border: none;
  border-radius: 10px;
  transition: 0.2s;
  cursor: pointer;
  height: 70px;
  width: 100px;
  font-size: 100%;
}

.buttons > button:hover {
  transform: scale(1.03);
  box-shadow: 3px 3px #1a1f25;
}

.buttons > button:active {
  transform: scale(1.03);
  box-shadow: 3px 3px #1a1f25;
}

.connStatusCircle {
  margin-left: 5px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.left span {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fafafa;
}

.genderSelect,.genderFilter {
  padding: 15px;
  outline: none;
  border: none;
  border-radius: 10px;
  transition: 0.2s;
  cursor: pointer;
  height: 70px;
  width: 100px;
  font-size: 100%;
  margin-left: 5px;
  margin-right: 5px;
}

@media screen and (max-width: 600px) {
  /* .buttons {
    display: grid;    
  } */
}

@media screen and (max-width: 1000px) {
  .buttons > button {
    height: 50px;
    width: 60px;
    padding: 10px;
    margin: 5px;
  }
  .genderSelect,.genderFilter {
    height: 50px;
    width: 70px;
    padding: 10px;
  }
}